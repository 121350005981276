<template>
  <div>
    <!-- 票据基本信息 -- 用的是工作台公共的子组件-里面包括票面预览和票面下载 -->
    <el-card>
      <WorkbenchPublicTitle :superInfoList="superInfoList">
        <template v-slot:titleText>
          <h3>票据基本信息</h3>
        </template>
      </WorkbenchPublicTitle>
      <el-table :data="superInfoList" border class="mt20">
        <el-table-column prop="packageNo" label="票据包号"></el-table-column>
        <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
        <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
        <el-table-column prop="acceptor" label="承兑人"></el-table-column>
        <el-table-column prop="applyDate" label="出票日期"></el-table-column>
        <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
        <el-table-column prop="ticketStatus" label="票据状态"></el-table-column>
      </el-table>
    </el-card>
    <!-- 质押信息 -->
    <el-card>
      <el-form :model="dePledgeInfo" 
               :rules="pledgeOneRules" 
               ref="pledgeOneRef" 
               label-width="180px"
               class="pledge-one-form">
        <el-form-item label="出质人：" prop="compName">
          <span>{{dePledgeInfo.compName}}</span>
        </el-form-item>
        <el-form-item label="出质人账号：" prop="acctNo">
          <span>{{dePledgeInfo.acctNo}}</span>
        </el-form-item>
        <el-form-item label="开户行：" prop="acctBankName">
          <span>{{dePledgeInfo.acctBankName}}</span>
        </el-form-item>
        <el-form-item label="开户行行号：" prop="acctBankNo">
          <span>{{dePledgeInfo.acctBankNo}}</span>
        </el-form-item>
        <el-form-item label="质押解除金额（元）：" prop="dePledgeAmt">
          <el-input v-model="dePledgeInfo.dePledgeAmt"></el-input>
        </el-form-item>
        <el-form-item label="申请日期：" prop="applyDate">
          <span>{{dePledgeInfo.applyDate}}</span>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea" v-model="dePledgeInfo.remark"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 按钮 -->
    <div class="btn">
			<el-button @click="handleBack">返回</el-button>
			<el-button type="primary" @click="handleSubmit">下一步</el-button>
		</div>
  </div>
</template>

<script>
  // 票面预览/票面下载
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  export default {
    name: 'ApplyRemoveOne',
    components: {
      WorkbenchPublicTitle
    },
    mounted(){
      // 票据id	--- 列表传过来的
      this.ticketId = this.$route.query.ticketId
      // 获取信息
      this.getInfo()
    },
    data(){
      return {
        ticketId: 52, // 票据id	--- 列表传过来的
        superInfoList: [], // 公共组件 - 票面预览/下载
        dePledgeInfo:{ // 质押解除申请信息	
          compName: '', // 出质人
          acctNo: '', //出质人账号
          acctBankName: '', // 开户行
          acctBankNo: '', // 开户行行号 
          dePledgeAmt: '', // 质押解除金额（元）
          applyDate: '', // 申请日期
          remark: '' // 备注
        },
        // 质押信息表单验证规则
        pledgeOneRules: {
          dePledgeAmt: [
            { required: true, message: '请输入质押金额', trigger: 'blur' },
          ],
          applyDate: [
            { required: true, message: '申请日期不能为空' },
          ]
        }
      }
    },
    methods: {
      // 获取信息
      async getInfo(){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/jc/mange/apply/info/${this.ticketId}`)
        if(res.code !== 200) return this.$message.error(res.msg)
        this.superInfoList = res.data.ticketBaseInfoList 
        this.dePledgeInfo = res.data.dePledgeInfo
      },
      // 返回
      handleBack(){
        this.$router.push({name: 'ApplyRemoveList'})
      },
      // 下一步
      handleSubmit(){
        this.$refs.pledgeOneRef.validate(async valid => {
          if(!valid) return
          if(this.dePledgeInfo.dePledgeAmt == '0') return this.$message.error('质押解除金额要大于0')
          let params = {
            ticketId: this.ticketId,
            dePledgeAmt: this.dePledgeInfo.dePledgeAmt,
            remark: this.dePledgeInfo.remark
          }
          const {data: res} = await this.$auth.post('/cpiaoju-ticket/jc/mange/apply/save', this.$qs.stringify(params))
          if(res.code !== 200) return this.$message.error(res.msg)
          this.$emit('handleSubmit', {activeStep: 2})
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .pledge-one-form{
    width: 60%;
    .el-select{
      width: 100% !important;
    }
  }
  .btn{
    text-align: center;
    margin: 50px auto 20px;
    .el-button{
      min-width: 120px;
      margin: 0 30px;
    }
  }
</style>