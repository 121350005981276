<template>
  <div>
    <!-- 收款信息 -->
    <el-card>
      <el-descriptions title="质押解除信息" :column="2">
        <el-descriptions-item label="出质人">{{dePledgeInfo.compName}}</el-descriptions-item>
        <el-descriptions-item label="出质人账号">{{dePledgeInfo.acctNo}}</el-descriptions-item>
        <el-descriptions-item label="开户行">{{dePledgeInfo.acctBankName}}</el-descriptions-item>
        <el-descriptions-item label="开户行行号">{{dePledgeInfo.acctBankNo}}</el-descriptions-item>
        <el-descriptions-item label="质押解除金额（元）">{{dePledgeInfo.dePledgeAmt}}</el-descriptions-item>
        <el-descriptions-item label="申请日期">{{dePledgeInfo.applyDate}}</el-descriptions-item>
        <el-descriptions-item label="备注">{{dePledgeInfo.remark == '' ? '无' : dePledgeInfo.remark}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 票据基本信息/母票基本信息 -- 用的是工作台公共的子组件-里面包括票面预览和票面下载 -->
    <el-card>
      <WorkbenchPublicTitle :superInfoList="superInfoList">
        <template v-slot:titleText>
          <h3>票据基本信息/母票基本信息</h3>
        </template>
      </WorkbenchPublicTitle>
      <el-table :data="superInfoList" border class="mt20">
        <el-table-column prop="packageNo" label="票据包号"></el-table-column>
        <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
        <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
        <el-table-column prop="acceptor" label="承兑人"></el-table-column>
        <el-table-column prop="applyDate" label="出票日期"></el-table-column>
        <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
        <el-table-column prop="ticketStatus" label="票据状态"></el-table-column>
      </el-table>
    </el-card>
    <!-- 子票基本信息 -->
    <el-card v-if="subBaseInfoList">
      <h3>子票基本信息</h3>
      <el-table :data="subBaseInfoList" border class="mt20">
        <el-table-column prop="subTicketInfo" label="子票信息"></el-table-column>
        <el-table-column prop="possessor" label="票据归属"></el-table-column>
        <el-table-column prop="packageSum" label="子票包金额"></el-table-column>
        <el-table-column prop="transferFlag" label="转让标记"></el-table-column>
      </el-table>
    </el-card>
    <!-- 按钮 -->
    <div class="btn">
			<el-button @click="handleBack">上一步</el-button>
			<el-button type="primary" @click="handleSubmit">确认提交</el-button>
		</div>
  </div>
</template>

<script>
  // 票面预览/票面下载
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  export default {
    name: 'ApplyRemoveTwo',
    components: {
      WorkbenchPublicTitle
    },
    mounted(){
      // 票据id	--- 列表传过来的
      this.ticketId = this.$route.query.ticketId
      // 获取信息
      this.getInfo()
    },
    data(){
      return {
        ticketId: 52, // 票据id	--- 列表传过来的
        dePledgeInfo: {}, // 质押解除信息	
        superInfoList: [], // 公共组件 - 票面预览/下载
        subBaseInfoList: [], // 子票基本信息 该对象==null时，为全质押
      }
    },
    methods: {
      // 获取信息
      async getInfo(){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/jc/mange/apply/check/${this.ticketId}`)
        if(res.code !== 200) return this.$message.error(res.msg)
        this.dePledgeInfo = res.data.dePledgeInfo
        this.superInfoList = res.data.baseInfoList
        this.subBaseInfoList = res.data.subBaseInfoList
      },
      // 上一步
      handleBack(){
        this.$emit('handleSubmit', {activeStep: 1})
      },
      // 确认提交
      async handleSubmit(){
        let params = {
          ticketId: this.ticketId
        }
        const {data: res} = await this.$auth.post('/cpiaoju-ticket/jc/mange/apply/submit', this.$qs.stringify(params))
        if(res.code !== 200) return this.$message.error(res.msg)
        this.$emit('handleSubmit', {activeStep: 3, text: res.data.text, handlerNo: res.data.handlerNo})
      }
    }
  }
</script>

<style lang="less" scoped>
  .el-descriptions{
    /deep/ .el-descriptions-item__cell{
      padding-bottom: 25px !important;
    }
  }
  .btn{
    text-align: center;
    margin: 50px auto 20px;
    .el-button{
      min-width: 120px;
      margin: 0 30px;
    }
  }
</style>